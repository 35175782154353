import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Typography,
  Card,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import { Configuration, OpenAIApi } from "openai";
import ReactGA from "react-ga4";

const allGenders = [
  {
    id: 1,
    value: "man",
    text: "Male",
  },
  {
    id: 2,
    value: "woman",
    text: "Female",
  },
  {
    id: 3,
    value: "non-binary",
    text: "Other",
  },
];

const openAiKey = `${process.env.REACT_APP_OPENAI_API_KEY}`;
const configuration = new Configuration({
  apiKey: openAiKey,
});

const Inputs = () => {
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [fact, setFact] = useState("");

  const [uppercase, setUppercase] = useState(true);

  const [lines, setLines] = useState([]);
  const [lineSelected, setLineSelected] = useState();

  const [loading, setLoading] = useState(false);

  const [conversation, setConversation] = useState([
    {
      role: "system",
      content:
        "You are the perfect wingman. Your task is to create amazing pickup lines that I can use for a potential significant other on a dating app",
    },
    {
      role: "user",
      content: `Create a funny tinder pickup line joke for a ${gender} named ${name}. ${fact}. I cannot stress this enough, make it hilarious.`,
    },
  ]);
  const [selectedLineConversation, setSelectedLineConversation] = useState();

  const openai = new OpenAIApi(configuration);

  // Generate Lines Function
  async function generateLines() {
    setLoading(true);
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
    // console.log(conversation);

    const response = await openai.createChatCompletion({
      model: "gpt-4",
      messages: conversation,
      max_tokens: 100,
      temperature: 1,
      n: 3,
    });
    const choices = response.data.choices.map((choice) => {
      return choice;
    });

    setLines(choices);
    setLoading(false);

    ReactGA.event({
      category: "generate",
      action: "generate",
      label: "Generate Click",
      value: 1,
    });
  }

  // Generate Lines Btn
  const generateLinesBtn = () => {
    setLineSelected();
    generateLines();
  };

  // Select Line and Add to Conversation
  const selectLine = async (line) => {
    setLineSelected(line);
    setConversation([
      ...conversation,
      { role: "assistant", content: line },
      {
        role: "user",
        content: `I really liked that line. Can you generate another line similar to this?`,
      },
    ]);
    setSelectedLineConversation(line);
  };

  useEffect(() => {
    if (selectedLineConversation) {
      generateLines();
    }
  }, [selectedLineConversation]);

  // Set Name, Gender, and Fun Fact
  useEffect(() => {
    setConversation([
      {
        role: "system",
        content:
          "You are the perfect wingman. Your task is to create amazing pickup lines that I can use for a potential significant other on a dating app",
      },
      {
        role: "user",
        content: `Create a funny tinder pickup line joke for a ${gender} named ${name}. ${fact}. I cannot stress this enough, make it hilarious.`,
      },
    ]);
  }, [name, gender, fact]);

  // Inputs
  const changeName = (e) => {
    setName(e.target.value);
  };

  const changeGender = (e) => {
    setGender(e.target.value);
  };

  const changeFact = (e) => {
    setFact(e.target.value);
  };

  // Change Case
  const changeCase = () => {
    setUppercase(!uppercase);
  };

  // Clear Form
  const clearForm = () => {
    setName("");
    setGender("");
    setFact("");
    setLines([]);
    setLineSelected();
    setConversation([
      {
        role: "system",
        content:
          "You are the perfect wingman. Your task is to create amazing pickup lines that I can use for a potential significant other on a dating app",
      },
      {
        role: "user",
        content: `Create a funny tinder pickup line joke for a ${gender} named ${name}. ${fact}. I cannot stress this enough, make it hilarious.`,
      },
    ]);
  };

  return (
    <>
      <Box className="container">
        <Box className="input-container">
          <TextField
            className="text-input"
            fullWidth
            color="secondary"
            label="Name"
            variant="outlined"
            spellCheck="false"
            value={name}
            onChange={changeName}
          />
          <TextField
            className="text-input"
            fullWidth
            color="secondary"
            select
            label="Gender"
            variant="outlined"
            spellCheck="false"
            value={gender}
            onChange={changeGender}
          >
            {allGenders.map((gender) => {
              return (
                <MenuItem
                  className="gender-item"
                  key={gender.id}
                  value={gender.value}
                  divider
                  sx={{ color: "#ffffff" }}
                >
                  {gender.text}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            className="text-input"
            fullWidth
            color="secondary"
            label="Fun Fact"
            variant="outlined"
            spellCheck="false"
            multiline
            minRows="4"
            value={fact}
            onChange={changeFact}
          />
        </Box>
        <Box className="btn-container">
          <Box
            className={`generate-btn ${
              !name || !gender ? "generate-disabled" : ""
            }`}
            onClick={generateLinesBtn}
            sx={{ pointerEvents: !name || !gender ? "none" : "auto" }}
          >
            Generate
          </Box>
        </Box>
        <Box className="btn-container-2">
          <FormControlLabel
            className="case"
            control={<Switch defaultChecked color="secondary" />}
            labelPlacement="top"
            label={uppercase ? "Uppercase" : "Lowercase"}
            onChange={changeCase}
          />
          <Box className="clear-form" onClick={clearForm}>
            Clear Form
          </Box>
        </Box>
        {/* {lines.length > 0 && (
          <>
            <Box className="line-message">
              <Typography>
                Click on a pickup line to generate more lines similar to it!
              </Typography>
            </Box>
          </>
        )} */}
        {lineSelected && (
          <>
            <Card className="selected-line">
              <Typography>
                {uppercase ? lineSelected : lineSelected.toLowerCase()}
              </Typography>
              <Box className="generate-similar-container">
                <Box className="generate-similar" onClick={generateLines}>
                  Generate Similar Lines
                </Box>
              </Box>
            </Card>
          </>
        )}

        <Box className="lines-container">
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <>
              {lines.map((line, index) => {
                return (
                  <Card className="lines" key={index}>
                    <Typography>
                      {uppercase
                        ? line.message.content
                        : line.message.content.toLowerCase()}
                    </Typography>
                    <Box className="generate-similar-container">
                      <Box
                        className="generate-similar"
                        onClick={() => selectLine(line.message.content)}
                      >
                        Generate Similar Lines
                      </Box>
                    </Box>
                  </Card>
                );
              })}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Inputs;
