import React from "react";
import { Box, Typography } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import ghostHeart from "../images/ghost-heart.png";

const Header = () => {
  const handleTwitterClick = () => {
    window.open("https://twitter.com/RiceFarmerNFT", "_blank");
  };
  return (
    <>
      <Box className="header-links">
        <TwitterIcon
          className="twitter-icon"
          color="secondary"
          onClick={handleTwitterClick}
        />
      </Box>
      <Box className="header-container">
        <img className="header-logo" src={ghostHeart} alt="Logo" />
        <Typography className="header-text">icebreakers</Typography>
      </Box>
    </>
  );
};

export default Header;
