import { useEffect } from "react";
import "./App.css";
import Header from "./components/Header";
import Inputs from "./components/Inputs";
import { Container } from "@mui/system";
import ReactGA from "react-ga4";

const googleApi = `${process.env.REACT_APP_GOOGLE_API_KEY}`;
ReactGA.initialize(googleApi);

function App() {
  // Google analytics
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);

  return (
    <>
      <Container maxWidth="sm" sx={{ position: "relative" }}>
        <Header />
        <Inputs />
      </Container>
    </>
  );
}

export default App;
